import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  Typography,
  Link,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function Copyright() {
  return (
    <Box>
      <Typography
        display='block'
        variant='caption'
        color='textSecondary'
        align='center'
      >
        {'Copyright © '}&nbsp;
        <Link
          color='inherit'
          component='a'
          href='https://objectlogic.de/'
          target='_blank'
        >
          ObjectLogic.de
        </Link>
        &nbsp;&nbsp;{new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

// footer content
const footers = [
  {
    title: 'merkantdo',
    content: [
      { description: 'Über uns', link: 'aboutUs' },
      { description: 'Unsere Apps', link: 'apps' },
    ],
  },
  {
    title: 'Für Unternehmen',
    content: [
      { description: 'Marketing mit merkantdo', link: 'marketing' },
      { description: 'Preisübersicht', link: 'pricing' },
      { description: 'Geschäft registrieren', link: 'registerStore' },
    ],
  },
  {
    title: 'Service',
    content: [
      { description: 'Kontakt', link: 'contact' },
      { description: 'Hilfe', link: 'help' },
    ],
  },
  {
    title: 'Rechtliches',
    content: [
      { description: 'Impressum', link: 'imprint' },
      { description: 'Datenschutz', link: 'privacy' },
      { description: 'AGB', link: 'terms' },
    ],
  },
];
/**
 * Footer Layout Component.
 *
 */
const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery('(max-width:800px)');

  // return
  return (
    <Container
      maxWidth={false}
      sx={{
        p: 4,
        minHeight: 168,
        background: theme.palette.primary.main,
      }}
    >
      <Box mb={2}>
        <Typography
          variant='h5'
          fontWeight='fontWeightBold'
          color='#F4511E'
          align='center'
        >
          Die ersten 100 registrierten Geschäfte sind dauerhaft kostenlos!
        </Typography>
      </Box>
      <Box
        //pl={6}
        sx={{
          pl: matches === true ? 2 : 6,
        }}
      >
        <Grid container spacing={4} justifyContent='space-evenly'>
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography
                variant='subtitle1'
                color='textPrimary'
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                }}
                gutterBottom
              >
                {footer.title}
              </Typography>
              <ul
                style={{
                  listStyleType: 'none',
                  padding: 0,
                  margin: 0,
                }}
              >
                {footer.content.map((item) => (
                  <li key={item.link}>
                    <RouterLink
                      to={`/${item.link}`}
                      style={{
                        textDecoration: 'none',
                      }}
                    >
                      <Typography
                        variant='subtitle1'
                        sx={{
                          mb: 0.5,
                          color: theme.palette.text.secondary,
                          '&:hover, &:focus, &:active': {
                            textDecoration: `underline ${theme.palette.text.secondary}`,
                          },
                        }}
                      >
                        {item.description}
                      </Typography>
                    </RouterLink>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mt={2}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default Footer;
